import React, { Component } from "react"
import { graphql } from "gatsby"
import { ChevronDown } from "react-feather"
import { Trans } from "gatsby-plugin-react-i18next"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Container from "../../components/Container"
import PortfolioCard from "../../components/PortfolioCard"
import ContactCard from "../../components/ContactCard"

import OrnamenUnion from "../../images/ornamen-union.png"

import "./style.scss"

class PortfolioPage extends Component {
  state = {
    showCategory: "Studi Kelayakan",
    portfolioCategory: [
      {
        id: 1,
        name: "Restruktur Perusahaan",
      },
      {
        id: 2,
        name: "Studi Kelayakan",
      },
      {
        id: 3,
        name: "Riset Pasar",
      },
      {
        id: 4,
        name: "Pemantauan Proyek",
      },
      {
        id: 5,
        name: "Advisory",
      },
    ],
  }

  handleShowCategory(name) {
    this.setState({
      showCategory: name,
    })
  }

  render() {
    const { portfolioCategory, showCategory } = this.state
    const { data } = this.props
    const { portfolioData } = data

    return (
      <Layout>
        <SEO
          title="Porfolio"
          keywords={[`provalindo`, `business`, `consultant`]}
        />
        <div className="portfoliopage">
          <section className="portfoliopage__banner">
            <Container className="portfoliopage__banner-container">
              <h1>
                <Trans>Portfolio Page Title</Trans>
              </h1>
              <p>
                <Trans>Portfolio Page Description</Trans>
              </p>
              <span>
                <Trans>Geser Kebawah</Trans>
              </span>
              <ChevronDown color="#1F3961" />
            </Container>
            <img
              src={OrnamenUnion}
              className="portfoliopage__banner-ornament"
              alt="ornament"
            />
          </section>
          <section className="portfoliopage__client">
            <Container className="portfoliopage__client-container">
              <div className="tabs">
                <div className="tabs__nav">
                  {portfolioCategory.map(item => {
                    return (
                      <div
                        key={item.id}
                        className={`tabs__nav-title ${
                          item.name === showCategory ? "tabs--is-active" : ""
                        }`}
                        onClick={() => this.handleShowCategory(item.name)}
                      >
                        <Trans>{item.name}</Trans>
                      </div>
                    )
                  })}
                </div>
                <div className="tabs__content">
                  {portfolioData &&
                    portfolioData.edges.map(item => {
                      return item.node.frontmatter.type === showCategory ? (
                        <PortfolioCard
                          key={item.node.id}
                          logo={
                            item.node.frontmatter.cover.childImageSharp.fluid
                          }
                          title={item.node.frontmatter.title}
                          year={item.node.frontmatter.year}
                          description={item.node.frontmatter.description}
                          link={item.node.fields.slug}
                        />
                      ) : null
                    })}
                </div>
              </div>
            </Container>
          </section>
          <section className="porfoliopage__contact">
            <Container>
              <ContactCard
                title="Contact Title"
                subtitle="Contact Description"
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default PortfolioPage

export const portfolioPageQuery = graphql`
  query {
    portfolioData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "client-page" }
          isShow: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            year
            type
            isTop
            isShow
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
