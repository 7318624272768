import React, { Component } from "react"
import { string } from "prop-types"
import { Trans } from "gatsby-plugin-react-i18next"

import Button from "../Button"
import OrnamentUpRight from "../../images/ornamen-up-right-blue.png"
import OrnamentDownRight from "../../images/ornamen-down-left.png"

import "./style.scss"

class ContactCard extends Component {
  static propTypes = {
    title: string,
    subtitle: string,
  }

  render() {
    const { title, subtitle } = this.props

    return (
      <div className="contact-card-wrapper">
        <img
          className="contact-card-wrapper__ornamen-up-right"
          src={OrnamentUpRight}
          alt="ornament"
        />
        <h2>
          <Trans>{title}</Trans>
        </h2>
        <p>
          <Trans>{subtitle}</Trans>
        </p>
        <Button isGatsbyLink to="/contact" size="large">
          <Trans>Hubungi Kami</Trans>
        </Button>
        <img
          className="contact-card-wrapper__ornamen-down-left"
          src={OrnamentDownRight}
          alt="ornament"
        />
      </div>
    )
  }
}

export default ContactCard
